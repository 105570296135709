/* Default styles */
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

/* Media query for smaller screens (e.g., mobile phones) */
@media (max-width: 768px) {
  /* Example: Adjust font size and margins for smaller screens */
  .content {
    width: 81%;
    margin: 1px;
  }
}

/* Media query for tablets (e.g., iPad) */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Example: Change the layout for tablets */
  
  .content {
    width: 81%;
    margin: 0px auto;
  }
}

/* Media query for larger screens (e.g., desktop) */
@media (min-width: 1425px) {
  /* Example: Adjust styles for larger screens */
  .content {
    width: 86%;
    margin: 0px auto;
  }
}
